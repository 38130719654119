<!-- 模块说明 -->
<template>
  <div class="container">
    <div class="title">海恒科技管理后台</div>
    <el-menu
      :default-active="defaultActive"
      class="el-menu-vertical-demo"
      background-color="#010161"
      text-color="#FFFFFF"
      active-text-color="#FFFFFF"
      router
      unique-opened
      :collapse="slideCollapse"
    >
      <template v-for="item in menusData">
        <el-menu-item
          :index="item.key"
          :route="item.path ? item.path : '/'"
          v-if="!item.hasChildren && item.power"
          :key="item.key"
        >
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
        <el-submenu
          :index="item.key"
          :route="item.path ? item.path : '/'"
          v-if="item.hasChildren && item.power"
          :key="item.key"
        >
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{ item.name }}</span>
          </template>
          <el-menu-item
            v-for="(subItem, index) in item.children"
            :index="subItem.path"
            :key="`${item.name}${index}`"
            >{{ subItem.name }}
          </el-menu-item>
        </el-submenu>
      </template>
    </el-menu>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      menusData: [
        {
          name: "文章管理",
          key: "manager",
          icon: "el-icon-tickets",
          path: "/manager",
          hasChildren: false,
          power: true,
          children: [],
        },
      ],
      slideCollapse: false,
    };
  },
  destroyed() {
    //移除监听窗口尺寸变化
    // window.removeEventListener("resize", this.resizeHandler);
  },
  watch: {},
  created() {
    // this.resizeHandler();
    //监听窗口尺寸变化
    // window.addEventListener("resize", this.resizeHandler);
    // this.powerFun();
  },
  methods: {
    //菜单权限
    powerFun() {
      const userInfo = JSON.parse(this.getCacheData("userInfo"));
      if (userInfo.type == "AGENT") {
        for (const i in this.menusData) {
          this.menusData[i].power = false;
          if (this.menusData[i].key == "account") {
            this.menusData[i].power = true;
          }
        }
      } else {
        this.menusData[2].power = false;
      }
    },
    //控制侧边栏展开/收缩
    resizeHandler() {
      if (window.innerWidth > 1500) {
        this.slideCollapse = false;
      } else {
        this.slideCollapse = true;
      }
    },
  },
  computed: {
    defaultActive() {
      let currentMenuPathMatches = this.$route.path.split("/")[1];
      return currentMenuPathMatches;
    },
  },
};
</script>
<style lang="less" scoped>
.el-menu {
  width: 240px;
}
.el-menu--collapse {
  width: 64px !important;
}
.el-menu-item {
  &.is-active {
    background: #464650 !important;
  }
}
.el-menu {
  border-right: none;
}
.title {
  font-size: 24px;
  color: white;
  text-align: center;
  padding: 20px 0;
  font-weight: bold;
}
.container {
  width: auto;
  height: 100vh;
  background-color: #333333;
}
</style>
