<!-- 模块说明 -->
<template>
  <el-container>
    <el-aside>
      <navigation></navigation>
    </el-aside>
    <el-container>
      <el-header>
        <el-button @click="LogOut">退出</el-button>
      </el-header>
      <el-main>
        <div class="flex searchEle">
          <el-button
            @click="addArticle(true)"
            icon="el-icon-circle-plus-outline"
            type="primary"
            >添加文章</el-button
          >
          <el-input
            class="waicengPhone"
            v-model="searchEle.title"
            placeholder="请输入文章标题"
            clearable
          ></el-input>
          <el-date-picker
            class="waicengPhone"
            v-model="searchEle.releaseTime"
            type="date"
            placeholder="选择日期"
            value-format="timestamp"
          >
          </el-date-picker>
          <el-select
            class="waicengPhone"
            v-model="searchEle.type"
            clearable
            placeholder="请选择文章分类"
          >
            <el-option
              v-for="item in optionsType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button icon="el-icon-search" type="primary" @click="searchOn"
            >搜索</el-button
          >
        </div>
        <div class="table-view">
          <el-table
            :data="tableData"
            stripe
            class="HNMR"
            v-loading="loading"
            max-height="620"
          >
            <el-table-column
              prop="title"
              label="文章标题"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column prop="liveRPTime" label="发布时间">
              <template slot-scope="scope">
                {{
                  Dateformat(scope.row.releaseTime, "YYYY-MM-DD HH:mm") || "--"
                }}
              </template>
            </el-table-column>
            <el-table-column label="分类名称">
              <template slot-scope="scope">
                {{ filterReturn(optionsType, scope.row.type) }}
              </template>
            </el-table-column>
            <el-table-column label="状态">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.releaseStatus == 'HAVE_RELEASED'"
                  style="color: #2ba471"
                  >已发布</span
                >
                <span v-else style="color: #e6a230">未发布</span>
              </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="openRecord(scope.row, scope.$index)"
                  type="text"
                  >查看</el-button
                >
                <el-button @click="addArticle(false, scope.row)" type="text"
                  >编辑</el-button
                >
                <el-button
                  @click="deleteArticleByReleaseId(scope.row.releaseId)"
                  type="text"
                  style="color: #f73a21"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-row>
            <el-pagination
              :current-page.sync="currentPage"
              :page-size="pageSize"
              background
              layout="total,sizes, prev, pager, next,jumper"
              :total="totalNum"
              @current-change="handelCurrentChange"
              @size-change="handleSizeChange"
            >
            </el-pagination>
          </el-row>
        </div>
      </el-main>
    </el-container>
    <el-dialog
      class="commonDialog"
      :title="popupOn.type ? '添加文章' : '编辑文章'"
      :visible.sync="popupOn.show"
      :before-close="resetForm"
      width="800px"
    >
      <div class="form-view">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="elRuleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="文章标题" prop="title">
            <el-input
              v-model="ruleForm.title"
              clearable
              type="textarea"
              placeholder="请输入文章标题"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="文章分类" prop="type">
            <el-select
              v-model="ruleForm.type"
              clearable
              placeholder="请选择文章分类"
              style="width: 100%"
            >
              <el-option
                v-for="item in optionsType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-checkbox v-model="ruleForm.enableTheExternalChain"
              >链接到外部网站</el-checkbox
            >
          </el-form-item>
          <el-form-item
            v-if="!ruleForm.enableTheExternalChain"
            label="文章内容"
            prop="contentsOfArticle"
          >
            <div style="border: 1px solid #ccc">
              <Toolbar
                style="border-bottom: 1px solid #ccc"
                :editor="editor"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style="height: 400px"
                v-model="ruleForm.contentsOfArticle"
                :defaultConfig="editorConfig"
                :mode="mode"
                @onCreated="onCreated"
              />
            </div>
          </el-form-item>
          <el-form-item v-else label="外部链接" prop="externalLinkUrl">
            <el-input
              v-model="ruleForm.externalLinkUrl"
              clearable
              placeholder="请输入外部链接"
            ></el-input>
          </el-form-item>
          <el-form-item label="文章摘要" prop="abstractOfArticle">
            <el-input
              v-model="ruleForm.abstractOfArticle"
              clearable
              type="textarea"
              :rows="4"
              placeholder="请输入文章摘要"
              show-word-limit
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item label="文章缩略图" prop="articleThumbnailUrl">
            <el-upload
              class="avatar-uploader"
              action="https://manage.haiheng.tech/api/uploadFile"
              :on-success="handleSuccess"
              :show-file-list="false"
              accept=".png,.jpeg,.gif,.jpg"
            >
              <el-image
                v-if="ruleForm.articleThumbnailUrl"
                style="width: 98px; height: 98px"
                :src="handelHttps(ruleForm.articleThumbnailUrl)"
                fit="cover"
              ></el-image>
              <i v-else class="el-icon-plus avatar-add"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="发布时间" prop="releaseTime">
            <el-date-picker
              v-model="ruleForm.releaseTime"
              type="datetime"
              placeholder="选择日期时间"
              default-time="08:00:00"
              value-format="timestamp"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="发布状态" prop="releaseStatus">
            <el-radio-group v-model="ruleForm.releaseStatus">
              <el-radio label="HAVE_RELEASED">发布</el-radio>
              <el-radio label="NOT_RELEASE">取消发布</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" plain @click="popupOn.show = false"
          >取 消</el-button
        >
        <el-button type="primary" @click="submitTo">确 定</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import navigation from "../../components/navigation.vue";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
export default {
  components: { navigation, Editor, Toolbar },
  props: [],
  data() {
    return {
      searchEle: {},
      optionsType: [
        {
          value: "COMPANY_NEWS",
          label: "公司新闻",
        },
        {
          value: "MEDIA_REPORT",
          label: "媒体报道",
        },
      ],
      tableData: [],
      totalNum: 0,
      currentPage: 1,
      pageSize: 10,
      popupOn: {
        show: false,
        type: true,
      },
      ruleForm: {
        contentsOfArticle: "",
        releaseStatus: "HAVE_RELEASED",
      },
      rules: {
        title: [{ required: true, message: "请输入文章标题", trigger: "blur" }],
        type: [
          { required: true, message: "请选择文章分类", trigger: "change" },
        ],
        contentsOfArticle: [
          { required: true, message: "请输入文章内容", trigger: "blur" },
        ],
        externalLinkUrl: [
          { required: true, message: "请输入外部链接", trigger: "blur" },
        ],
        abstractOfArticle: [
          { required: true, message: "请输入文章摘要", trigger: "blur" },
        ],
        articleThumbnailUrl: [
          { required: true, message: "请上传图片", trigger: "blur" },
        ],
        releaseTime: [
          { required: true, message: "请选择发布时间", trigger: "change" },
        ],
        releaseStatus: [
          { required: true, message: "请选择发布状态", trigger: "change" },
        ],
      },
      editor: null,
      toolbarConfig: {
        excludeKeys: ["group-video"],
      },
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          //图片上传配置（不配置会报错无法上传本地图片）
          uploadImage: {
            server: "https://manage.haiheng.tech/api/uploadFile",
            fieldName: "file",
            maxFileSize: 20 * 1024 * 1024,
            headers: {
              //所以token放这里
              token: JSON.parse(localStorage.getItem("userInfo")).token,
            },
            customInsert(res, insertFn) {
              // res 即服务端的返回结果
              // 从 res 中找到 url alt href ，然后插图图片
              insertFn("https://" + res.data);
            },
          },
        },
      },
      mode: "default", // or 'simple'
      loading: false,
    };
  },
  watch: {},
  created() {
    this.getArticleListPage();
  },
  methods: {
    //删除文章
    deleteArticleByReleaseId(id) {
      this.$confirm("是否确认删除此文章？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.http
            .post("/article/deleteArticleByReleaseId/" + id, {})
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getArticleListPage();
              } else {
                this.$message.error(res.data.errorMsg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch(() => {});
    },
    //添加文章提交
    submitTo() {
      this.$refs.elRuleForm.validate((valid) => {
        if (valid) {
          this.http
            .post("/article/addArticleOrUpdateArticle", this.ruleForm)
            .then((res) => {
              if (res.data.success) {
                this.$message.success(
                  this.popupOn.type ? "添加成功" : "编辑成功"
                );
                this.popupOn.show = false;
                this.getArticleListPage();
              } else {
                this.$message.error(res.data.errorMsg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    //关闭弹窗时
    resetForm() {
      console.log("测试");
      this.ruleForm = {
        contentsOfArticle: "",
        releaseStatus: "HAVE_RELEASED",
      };
      this.$refs.elRuleForm.resetFields();
      this.popupOn.show = false;
    },
    //添加/编辑文章
    addArticle(type, row) {
      this.popupOn.type = type;
      if (!type) {
        this.ruleForm = row;
      }
      this.popupOn.show = true;
    },
    //文章搜索
    searchOn() {
      this.currentPage = 1;
      this.getArticleListPage();
    },
    //获取文章列表
    getArticleListPage() {
      let params = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      this.loading = true;
      params = Object.assign({}, this.searchEle, params);
      this.http
        .get("/article/getArticleListPage", params)
        .then((res) => {
          this.tableData = res.data.data;
          this.totalNum = res.data.totalRecords;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    handleSuccess(file) {
      //图片上传成功
      this.ruleForm.articleThumbnailUrl = file.data;
      this.$forceUpdate();
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    //查看预览跳转新页面
    openRecord(row, i) {
      let routeUrl = this.$router.resolve(
        `/details?hotspotList=${encodeURIComponent(
          JSON.stringify(this.tableData).replace(/%/g, "%25")
        )}&index=${i}`
      );
      if (row.enableTheExternalChain) {
        window.open(this.handelHttps(row.externalLinkUrl), "_blank");
      } else {
        window.open(this.handelHttps("haiheng.tech" + routeUrl.href), "_blank");
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getArticleListPage();
    },
    //翻页
    handelCurrentChange(page) {
      this.currentPage = page;
      this.getArticleListPage();
    },
    //退出登录
    LogOut() {
      this.removeCacheData("userInfo");
      this.$message.success("退出成功，即将返回官网首页");
      setTimeout(() => {
        this.$router.replace("/");
      }, 2000);
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器123
  },
  computed: {},
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.w-e-full-screen-container {
  z-index: 10000;
}
.dialog-footer {
  border-top: 1px solid #d9d9d9;
  width: 100%;
  padding-top: 20px;
}
.form-view {
  max-height: 600px;
  overflow: auto;
  padding-right: 20px;
}
.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  width: 96px;
  height: 96px;
  font-size: 26px;
  float: left;

  .avatar-add {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
  }
  .avatar {
    width: 100%;
    height: 100%;
    background-size: contain;
  }
}
.table-view {
  padding: 20px;
}
.searchEle {
  .el-button {
    margin-right: 20px;
  }
  justify-content: flex-start;
  padding: 20px;
  border-bottom: 2px solid #f3f3f3;
}
//搜索条件样式
.waicengPhone {
  width: 228px !important;
  flex-shrink: 0;
  margin-right: 20px;
}
.el-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 64px;
  background: #ffffff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
}
.el-main {
  background: #ffffff;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.15);
  margin: 20px;
  padding: 0 !important;
}
.el-aside {
  width: auto !important;
}
</style>
